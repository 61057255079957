<template>
  <div class="error-page">
    <!-- <img class="error-img" src="../assets/img/error.png" alt /> -->
    <p class="error__text">抱歉，您所访问的页面不存在</p>
    <div class="error-handle">
      <router-link to="/">
        <el-button type="primary" size="large">返回首页</el-button>
      </router-link>
      <el-button class="error-btn" type="primary" size="large" @click="goBack">返回上一页</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "404",
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .error-img {
    width: 737px;
    height: 463px;
    margin-bottom: 60px;
  }
  .error__text {
    font-size: 16px;
    color: #101010;
    text-align: center;
  }
  .error-handle {
    margin-top: 30px;
    .error-btn {
      margin-left: 100px;
    }
  }
}
</style>

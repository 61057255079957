var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "error-page" }, [
    _c("p", { staticClass: "error__text" }, [
      _vm._v("抱歉，您所访问的页面不存在")
    ]),
    _c(
      "div",
      { staticClass: "error-handle" },
      [
        _c(
          "router-link",
          { attrs: { to: "/" } },
          [
            _c("el-button", { attrs: { type: "primary", size: "large" } }, [
              _vm._v("返回首页")
            ])
          ],
          1
        ),
        _c(
          "el-button",
          {
            staticClass: "error-btn",
            attrs: { type: "primary", size: "large" },
            on: { click: _vm.goBack }
          },
          [_vm._v("返回上一页")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }